// Can be DEV,UAT,QA LOCAL_HOST,PROD
export const ENVIRONMENT_TYPE: string | undefined = import.meta.env.VITE_REACT_APP_ENV;

// ENVIRONMENT_TYPE !== "PROD" && console.log(import.meta.env);

export const IS_PROD = ENVIRONMENT_TYPE === 'PROD';

interface configInterface {
  baseApiUrl: string;
  ssoUrl: string;
  loginHash: string;
  pmsUrl: string;
  sentryDSN: string;
  globalApi: {
    endPoint: string;
    token: string;
  };
  emqxBrokerConfig: {
    clean: boolean;
    connectTimeout: number;
    clientId: string;
    username: string;
    password: string;
    host: string;
    port: number;
    path: string;
    protocol: 'wss' | 'ws' | 'mqtt' | 'mqtts' | 'tcp' | 'ssl' | 'wx' | 'wxs' | undefined;
  };
  unamiId:string
}

interface mapInterface {
  LOCAL_HOST: configInterface;
  UAT: configInterface;
  PROD: configInterface;
}

const LOCAL_HOST_CONFIG: configInterface = {
  baseApiUrl: 'http://localhost:4037',
  ssoUrl: 'https://uat-sso.isha.in',
  loginHash: 'ae29142aabe3ac4faefdca7abf269d924ffb8e7e0dbb6c30f6f0f0bd5a6ff3a9',
  pmsUrl: 'https://uat-profile.isha.in/services/pms',
  sentryDSN: 'xxx',
  globalApi: {
    endPoint: 'https://uat-gapi.sadhguru.org',
    token: 'hmCbkUB4rHuRt2x2YMZ9QdpPFrgw3dqPUjpTbNtD6phPLjJKyqsXjtPzJAayusLd',
  },
  emqxBrokerConfig: {
    clean: true,
    connectTimeout: 4000,
    clientId: '',
    username: 'ipd_user',
    host: 'uat-emqx.sadhguru.org',
    path: '/mqtt',
    port: 443,
    protocol: 'wss',
    password: '',
  },
  unamiId:"xyzabc"
};

const UAT_CONFIG: configInterface = {
  baseApiUrl: 'https://uipd-user-api.sadhguru.org',
  ssoUrl: 'https://uat-sso.isha.in',
  loginHash: '2b86935aea22e67362a52ddbfbee6c58a85ceef1d701bf9acc49bebc450d5436',
  pmsUrl: 'https://uat-profile.isha.in/services/pms',
  sentryDSN:
    'https://ee74992a04af22092085c500e861ef6b@o665977.ingest.sentry.io/4506693186289664',
  globalApi: {
    endPoint: 'https://uat-gapi.sadhguru.org',
    token: 'hmCbkUB4rHuRt2x2YMZ9QdpPFrgw3dqPUjpTbNtD6phPLjJKyqsXjtPzJAayusLd',
  },
  emqxBrokerConfig: {
    clean: true,
    connectTimeout: 4000,
    clientId: '',
    username: 'ipd_user',
    host: 'uat-emqx.sadhguru.org',
    path: '/mqtt',
    port: 443,
    protocol: 'wss',
    password: '',
  },
  unamiId:"ae5ab1bf-5512-4cb6-a228-b5cb121a1210"
};

const PROD_CONFIG: configInterface = {
  baseApiUrl: 'https://events-user-api.sadhguru.org',
  ssoUrl: 'https://ishalogin.sadhguru.org',
  loginHash: 'ff3ae318edbdcb41732883360744dc8a13e1053ff50501032245c8a388a253a9',
  pmsUrl: 'https://pms.sadhguru.org/services/pms',
  sentryDSN:
    'https://ee74992a04af22092085c500e861ef6b@o665977.ingest.sentry.io/4506693186289664',
  globalApi: {
    endPoint: 'https://vihanga.isha.in',
    token: 'BfcEgbjVffLhkE9xeJWW3hZHTrDPmp79ugfG3Hcgpe73MLAdx5va3mmnqC2ax5d5',
  },
  emqxBrokerConfig: {
    clean: true,
    connectTimeout: 4000,
    clientId: '',
    username: 'ipd_user',
    host: 'emqx.sadhguru.org',
    path: '/mqtt',
    port: 443,
    protocol: 'wss',
    password: '',
  },
  unamiId:"abc200c7-2465-4bd5-a76b-184d47e81fe7"
};

const CONFIG_MAP: mapInterface = {
  LOCAL_HOST: LOCAL_HOST_CONFIG,
  UAT: UAT_CONFIG,
  PROD: PROD_CONFIG,
};

const selector = () => {
  switch (ENVIRONMENT_TYPE) {
    case 'LOCAL_HOST':
      return CONFIG_MAP.LOCAL_HOST;
    case 'UAT':
      return CONFIG_MAP.UAT;
    case 'PROD':
      return CONFIG_MAP.PROD;
    default:
      return CONFIG_MAP.UAT;
  }
};

export const CONFIG = selector();
