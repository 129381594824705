import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';

import AppRouter from './AppRouter';
import FullScreenLoading from './components/FullScreenLoading';
import { GLOBAL_QUERY_CLIENT } from './constants';
// import { resetUIState } from './store/uiStore';
import EMQXStatusBar from './components/EMQXStatusBar';
import TimeKeeperUi from './components/TimeKeeperUI';
import { useGlobalState } from './store';
import { initCountryData, initLanguages } from './utils/configMaps';
import { closeMqtt, unSubscribeToProgramBroadcast } from './utils/events';
import { initSentry } from './utils/sentry';
import { seedServerTime } from './utils/timeKeeper';

function App() {
  const [isReady, setIsReady] = useState<boolean>(false);
  const userId = useGlobalState((state) => state.userId);

  useEffect(() => {
    const init = async () => {
      try {
        // initOpenReplay();
        initSentry();
        unSubscribeToProgramBroadcast();
        // await Promise.all([initLanguages(), initTimezones(), initCountryData()]);
        // resetUIState();
        // await initTimezones();
        await Promise.all([seedServerTime(), initLanguages(), initCountryData()]);
        // await seedServerTime();
        // await initLanguages();
        // await initCountryData();
        setIsReady(true);
      } catch (error) {
        console.log('error in init-->', error);
      }
    };
    init();
    return () => {
      closeMqtt();
    };
  }, []);

  if (!isReady) {
    return <div>...</div>;
  }

  return (
    <>
      {userId && <EMQXStatusBar />}
      <TimeKeeperUi />
      <QueryClientProvider client={GLOBAL_QUERY_CLIENT}>
        <FullScreenLoading />
        <Toaster position="top-center" reverseOrder={false} />
        <AppRouter />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}
export default App;
