// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// Object.defineProperties(exports, { __esModule: { value: true }, [Symbol.toStringTag]: { value: "Module" } });
const isOptionGroupArray = (arr) =>
  Array.isArray(arr) && arr.length > 0 && 'options' in arr[0];
const filterFieldsByComparator = (field, fields, operator) => {
  if (!field.comparator) {
    const filterOutSameName = (f) => f.name !== field.name;
    if (isOptionGroupArray(fields)) {
      return fields.map((og) => ({
        ...og,
        options: og.options.filter(filterOutSameName),
      }));
    }
    return fields.filter(filterOutSameName);
  }
  const filterByComparator = (fieldToCompare) => {
    if (field.name === fieldToCompare.name) {
      return false;
    }
    if (typeof field.comparator === 'string') {
      return field[field.comparator] === fieldToCompare[field.comparator];
    }
    return field.comparator(fieldToCompare, operator);
  };
  if (isOptionGroupArray(fields)) {
    return fields
      .map((og) => ({ ...og, options: og.options.filter(filterByComparator) }))
      .filter((og) => og.options.length > 0);
  }
  return fields.filter(filterByComparator);
};
const getValueSourcesUtil = (fieldData, operator, getValueSources) => {
  const fd = fieldData != null ? fieldData : {};
  if (fd.valueSources) {
    if (typeof fd.valueSources === 'function') {
      return fd.valueSources(operator);
    }
    return fd.valueSources;
  }
  if (getValueSources) {
    const vals = getValueSources(fd.name, operator);
    if (vals) return vals;
  }
  return ['value'];
};
const uniqByName = (originalArray) => {
  const names = /* @__PURE__ */ new Set();
  const newArray = [];
  originalArray.forEach((el) => {
    if (!names.has(el.name)) {
      names.add(el.name);
      newArray.push(el);
    }
  });
  return newArray;
};
const isJsonLogicVar = (logic) => typeof logic === 'object' && 'var' in logic;
const isRQBJsonLogicVar = (logic) =>
  isJsonLogicVar(logic) && typeof logic.var === 'string';
const isJsonLogicEqual = (logic) => typeof logic === 'object' && '==' in logic;
const isJsonLogicStrictEqual = (logic) => typeof logic === 'object' && '===' in logic;
const isJsonLogicNotEqual = (logic) => typeof logic === 'object' && '!=' in logic;
const isJsonLogicStrictNotEqual = (logic) => typeof logic === 'object' && '!==' in logic;
const isJsonLogicNegation = (logic) => typeof logic === 'object' && '!' in logic;
const isJsonLogicDoubleNegation = (logic) => typeof logic === 'object' && '!!' in logic;
const isJsonLogicOr = (logic) => typeof logic === 'object' && 'or' in logic;
const isJsonLogicAnd = (logic) => typeof logic === 'object' && 'and' in logic;
const isJsonLogicGreaterThan = (logic) => typeof logic === 'object' && '>' in logic;
const isJsonLogicGreaterThanOrEqual = (logic) =>
  typeof logic === 'object' && '>=' in logic;
const isJsonLogicLessThan = (logic) => typeof logic === 'object' && '<' in logic;
const isJsonLogicLessThanOrEqual = (logic) => typeof logic === 'object' && '<=' in logic;
const isJsonLogicInArray = (logic) =>
  typeof logic === 'object' && 'in' in logic && Array.isArray(logic.in[1]);
const isJsonLogicInString = (logic) =>
  typeof logic === 'object' && 'in' in logic && !Array.isArray(logic.in[1]);
const isRQBJsonLogicStartsWith = (logic) =>
  typeof logic == 'object' && 'startsWith' in logic;
const isRQBJsonLogicEndsWith = (logic) => typeof logic == 'object' && 'endsWith' in logic;
const emptyRuleGroup = { combinator: 'and', rules: [] };
const parseJsonLogic = (rqbJsonLogic, options) => {
  const listsAsArrays = !!(options == null ? void 0 : options.listsAsArrays);
  let fieldsFlat = [];
  const getValueSources = options == null ? void 0 : options.getValueSources;
  if (options) {
    const { fields } = options;
    if (fields) {
      const fieldsArray = Array.isArray(fields)
        ? fields
        : Object.keys(fields)
            .map((fld) => ({ ...fields[fld], name: fld }))
            .sort((a, b) => a.label.localeCompare(b.label));
      if (isOptionGroupArray(fieldsArray)) {
        fieldsFlat = uniqByName(
          fieldsFlat.concat(...fieldsArray.map((opt) => opt.options)),
        );
      } else {
        fieldsFlat = uniqByName(fieldsArray);
      }
    }
  }
  function fieldIsValid(fieldName, operator, subordinateFieldName) {
    if (fieldsFlat.length === 0) return true;
    let valid = false;
    const primaryField = fieldsFlat.find((ff) => ff.name === fieldName);
    if (primaryField) {
      if (
        !subordinateFieldName &&
        operator !== 'notNull' &&
        operator !== 'null' &&
        !getValueSourcesUtil(primaryField, operator, getValueSources).some(
          (vs) => vs === 'value',
        )
      ) {
        valid = false;
      } else {
        valid = true;
      }
      if (valid && !!subordinateFieldName) {
        if (
          getValueSourcesUtil(primaryField, operator, getValueSources).some(
            (vs) => vs === 'field',
          ) &&
          fieldName !== subordinateFieldName
        ) {
          const validSubordinateFields = filterFieldsByComparator(
            primaryField,
            fieldsFlat,
            operator,
          );
          if (!validSubordinateFields.find((vsf) => vsf.name === subordinateFieldName)) {
            valid = false;
          }
        } else {
          valid = false;
        }
      }
    }
    return valid;
  }
  function processLogic(logic, outermost) {
    if (outermost && typeof logic !== 'object') {
      return false;
    }
    const key = Object.keys(logic)[0];
    const keyValue = logic[key];
    if (isJsonLogicAnd(logic)) {
      return {
        combinator: 'and',
        rules: logic.and.map((l) => processLogic(l)).filter(Boolean),
      };
    } else if (isJsonLogicOr(logic)) {
      return {
        combinator: 'or',
        rules: logic.or.map((l) => processLogic(l)).filter(Boolean),
      };
    } else if (isJsonLogicNegation(logic)) {
      const rule2 = processLogic(logic['!']);
      return rule2 ? { combinator: 'and', rules: [rule2], not: true } : false;
    } else if (isJsonLogicDoubleNegation(logic)) {
      const rule2 = processLogic(logic['!!']);
      return rule2 || false;
    }
    let rule = false;
    let field = '';
    let operator = '=';
    let value = '';
    let valueSource = void 0;
    if (
      isJsonLogicEqual(logic) ||
      isJsonLogicStrictEqual(logic) ||
      isJsonLogicNotEqual(logic) ||
      isJsonLogicStrictNotEqual(logic) ||
      isJsonLogicGreaterThan(logic) ||
      isJsonLogicGreaterThanOrEqual(logic) ||
      isJsonLogicLessThan(logic) ||
      isJsonLogicLessThanOrEqual(logic) ||
      isJsonLogicInString(logic) ||
      isRQBJsonLogicStartsWith(logic) ||
      isRQBJsonLogicEndsWith(logic)
    ) {
      const [first, second] = keyValue;
      if (isRQBJsonLogicVar(first) && typeof second !== 'object') {
        field = first.var;
        value = second;
      } else if (typeof first !== 'object' && isRQBJsonLogicVar(second)) {
        field = second.var;
        value = first;
      } else if (isRQBJsonLogicVar(first) && isRQBJsonLogicVar(second)) {
        field = first.var;
        value = second.var;
        valueSource = 'field';
      } else {
        return false;
      }
      if (isJsonLogicEqual(logic) || isJsonLogicStrictEqual(logic)) {
        operator = '=';
      } else if (isJsonLogicNotEqual(logic) || isJsonLogicStrictNotEqual(logic)) {
        operator = '!=';
      } else if (isJsonLogicInString(logic)) {
        operator = 'contains';
      } else if (isRQBJsonLogicStartsWith(logic)) {
        operator = 'beginsWith';
      } else if (isRQBJsonLogicEndsWith(logic)) {
        operator = 'endsWith';
      } else {
        operator = key;
      }
      if (fieldIsValid(field, operator, valueSource === 'field' ? value : void 0)) {
        rule = { field, operator, value, valueSource };
      }
    } else if (isJsonLogicInArray(logic) && isRQBJsonLogicVar(keyValue[0])) {
      field = keyValue[0].var;
      operator = 'in';
      if (logic.in[1].every(isRQBJsonLogicVar)) {
        valueSource = 'field';
        const fieldList = logic.in[1]
          .map((el) => el.var)
          .filter((sf) => fieldIsValid(field, operator, sf));
        value = listsAsArrays ? fieldList : fieldList.join(',');
      } else {
        if (
          logic.in[1].every((el) => typeof el === 'string') ||
          logic.in[1].every((el) => typeof el === 'number') ||
          logic.in[1].every((el) => typeof el === 'boolean')
        ) {
          value = listsAsArrays
            ? logic.in[1]
            : logic.in[1].map((el) => `${el}`).join(',');
        }
      }
      if (value.length > 0) {
        rule = { field, operator, value, valueSource };
      }
    }
    return !rule
      ? false
      : outermost
      ? { combinator: 'and', rules: [rule], not: false }
      : rule;
  }
  let logicRoot = rqbJsonLogic;
  if (typeof rqbJsonLogic === 'string') {
    try {
      logicRoot = JSON.parse(rqbJsonLogic);
    } catch (err) {
      return emptyRuleGroup;
    }
  }
  const result = processLogic(logicRoot, true);
  return !result ? emptyRuleGroup : result;
};
// exports.parseJsonLogic = parseJsonLogic;
export default parseJsonLogic;
